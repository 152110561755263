<template>
  <div>
    <h1>Maksed</h1>
    <v-btn
        v-show="true"
        dark
        absolute
        top
        right
        @click="dialog = true"
    >
      Lisa kontoväljavõte
    </v-btn>
    <v-data-table
        :headers="headers"
        :hide-default-footer=true
        :items="payments"
        :item-class= "rowClasses"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        class="payments-table"
        :loading="loading"
        :items-per-page=20
        loading-text="Laeb... palun oota"
        @click:row="rowClick"
    >
    </v-data-table>

    <Pagination :pagination="this.pagination"
                :page="this.page"
                v-on:load_new_page="loadNewPage">

    </Pagination>

    <div v-if="unmatchedTransations.length" class="mt-5">
      <h1>Mitte kattuvad tehingud</h1>
      <v-data-table
          :headers="unmatchedTransationsHeader"
          :items="unmatchedTransations"
          class="not-matching mt-5"
          :items-per-page=15
      >
      </v-data-table>
    </div>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        @keydown.esc="dialog = false"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Lisa kontoväljavõte</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
              >
                <v-file-input
                    v-model="file"
                    placeholder="Lisa väljavõte"
                ></v-file-input>
              </v-col>
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
              >
                <v-select
                    v-model="bank"
                    :items="banks"
                    label="Vali pank"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Katkesta
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="processPayments"
          >
            Lisa
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "../components/Pagination";
export default {
  name: "Payments",
  components: {Pagination},
  data () {
    return {
      file: null,
      dialog: false,
      expanded: [],
      singleExpand: false,
      payments: [],
      unmatchedTransations: [],
      bank: null,
      banks: [
        { text: 'LHV', value: 'lhv' },
        { text: 'SEB', value: 'seb' }
      ],
      loading: true,
      headers: [
        { text: 'Staatus', align: 'start', value: 'status' },
        { text: 'Pood', value: 'shop' },
        { text: 'Provider', value: 'provider' },
        { text: 'Kirjeldus', value: 'description' },
        { text: 'Summa', value: 'amount' },
        { text: 'Valuuta', value: 'currency' },
        { text: 'Kuupäev', value: 'date_time' },
        { text: '', value: 'data-table-expand' },
      ],
      unmatchedTransationsHeader: [
        { text: 'Kirjeldus', value: 'description' },
        { text: 'Summa', value: 'amount' },
        { text: 'Nimi', value: 'name' },
        { text: 'Konto nr', value: 'account' },
        { text: 'Kuupäev', value: 'date' },
      ],
      page: 1,
      pagination: 0,
    }
  },
  methods: {
    processPayments: function () {
      let formData = new FormData();
      formData.append('file', this.file, this.file.name);
      formData.append('bank', this.bank);
      this.loading = true;
      axios.get(axios.defaults.baseURL + '/sanctum/csrf-cookie')
          .then(response => {
            axios
                .post(axios.defaults.baseURL + '/payments/check', formData)
                .then(response => {
                  this.unmatchedTransations = response.data
                  console.log( this.unmatchedTransations);
                  this.fetchPayments();
                })
                .catch(error => {
                  console.log({error});
                });
          });
      this.dialog = false;
    },
    rowClasses: function (item) {
      return item.status
    },
    loadNewPage: function (page) {
      this.page = page;
      this.loading = true;
      this.fetchPayments();
    },
    fetchPayments: function () {
      let paymentUrl = axios.defaults.baseURL + '/payments';
      if (this.page > 1) {
        paymentUrl = paymentUrl + '/' + this.page;
      }
      axios.get(paymentUrl).then((response) => {
        this.payments = response.data.payments;
        this.pagination = response.data.pages;
        this.loading = false;
      })
    }
  },
  mounted () {
    this.fetchPayments();
  }
}
</script>

<style scoped>

</style>